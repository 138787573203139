body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.responsiveHideSection {
  visibility: visible;

  @media (max-width: 1000px) {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
  }
}

/* ********************************** QUOTER ********************************************* */
/* LOADER */
@keyframes loader-spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes loader-path {
  0% {
    stroke-dasharray: 0, 580, 0, 0, 0, 0, 0, 0, 0;
  }

  50% {
    stroke-dasharray: 0, 450, 10, 30, 10, 30, 10, 30, 10;
  }

  100% {
    stroke-dasharray: 0, 580, 0, 0, 0, 0, 0, 0, 0;
  }
}

.LoadingContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LoadingWrapper {
  position: relative;
  width: 400px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: visible;
}

.LoadingWrapper span {
  position: relative;
  /* top: 75px; */
  font-size: 20px;
  letter-spacing: 4px;
  color: #2e1d6e;
  /* left: 5px; */
  text-align: center;
}

.LoadingWrapper .svg-calLoader {
  width: 230px;
  height: 230px;
  transform-origin: 115px 115px;
  animation: 1.4s linear infinite loader-spin;
}

.LoadingWrapper .cal-loader__plane {
  fill: #6d71f6;
  filter: drop-shadow(-2px -2px 2px #2d1b6e);
}

.LoadingWrapper .cal-loader__path {
  stroke: #2d1b6e;
  animation: 1.4s ease-in-out infinite loader-path;
}

/* CARDS */
.customCardContainer {
  overflow: unset;
}

.customComponentCard > * {
  padding: 0;
  margin: 0;
}

.cardContainerHoverAnimation .customComponentCard {
  transition: transform 250ms;
  pointer-events: none;
}

.cardContainerHoverAnimation:hover .customComponentCard {
  transform: translateY(-10px);
  transition: transform 250ms;
}

#responsiveText,
#responsiveTitleText {
  font-size: clamp(0.65rem, 2vw, 0.9rem);
}

#normalTitleText {
  font-size: 0.9rem;
}

/* INPUT COUNTRIES */
.smallDrawer {
  height: 40px;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid lightgray;
}
.smallDrawer p {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 5%;
}

.smallDrawer:hover {
  background-color: rgba(113, 115, 246, 0.1);
}

.listDrawer {
  position: absolute;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid lightgrey;
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  box-shadow: 8px 8px 10px 1px rgba(14, 0, 28, 0.15);
  z-index: 99;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 350ms;
}

.innerDrawerContainer {
  position: relative;
  width: 95%;
  height: 95%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.innerDrawerContainer > p {
  margin: 0;
}
.innerDrawerContainer > * p {
  margin: 0;
}

.listsDrawerContainer {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
}

.innerDrawerContainer .postalCodesListContainer,
.userAddressesListContainer {
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  margin-top: 5px;
}

.DrawerOpen {
  opacity: 1;
  height: 100px;
  visibility: visible;
}

.DrawerClosed {
  opacity: 0;
  height: 90px;
  visibility: hidden;
}

.ListDrawerOpen {
  height: auto;
}

.ListDrawerClosed {
  height: 40px;
}

.bigDrawer {
  height: 40px;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.smallDrawer {
  height: 40px;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid lightgray;
}
.smallDrawer p {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 5%;
}

.smallDrawer:hover {
  background-color: rgba(113, 115, 246, 0.1);
}

/* POSTAL CODE QUOTE */
.responsiveHideSection {
  visibility: visible;

  @media (max-width: 1000px) {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
  }
}

/* SELECT FAV PARCEL */
.drfont {
  font-family: 'Roboto', sans-serif;
}

.floating-button-back {
  position: fixed;
  display: flex;
  bottom: 65px;
  right: -2px;
  background-color: #2e1d6e;
  color: #fff;
  border-radius: 50% 8px 50% 50%;
  padding: 12px;
  font-size: 25px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  box-shadow: 0 3px 3px 0 #00000044;
  z-index: 999;
}

@media (max-width: 1110px) {
  .floating-button-back {
    right: 3px;
    bottom: 75px;
  }
}

@media (max-width: 580px) {
  .floating-button-back {
    right: 13px;
    bottom: 83px;
  }
}

.background {
  background: rgb(29,54,117);
  background: linear-gradient(90deg, rgba(29,54,117,1) 0%, rgba(29,39,117,1) 6%, rgba(29,29,117,1) 45%);
}

.ant-card {
  box-shadow: 1px 5px 20px 5px rgba(0, 0, 0, 0.14);
}

.ant-card .ant-card-body {
  padding: 30px;
}

.ant-card-bordered {
  background: #ffffff;
  border: none;
  border-radius: 15px;
}

.containerStatistics {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
}

/* Efecto titilante rojo (de rojo intenso a rojo claro) */
@keyframes blink-red {
  0% { background-color: #ff4d4f; } /* Rojo intenso */
  50% { background-color: #ffcccc; } /* Rojo claro */
  100% { background-color: #ff4d4f; }
}

/* Efecto titilante naranja (de naranja intenso a naranja claro) */
@keyframes blink-orange {
  0% { background-color: #ffa940; } /* Naranja intenso */
  50% { background-color: #ffe7cc; } /* Naranja claro */
  100% { background-color: #ffa940; }
}

@keyframes blink-green {
  0% { background-color: #00ff3b; } /* Verde intenso */
  50% { background-color: #52d06f; } /* Verde claro */
  100% { background-color: #9ddfac; }
}

/* Clases de parpadeo */
.blink-red {
  animation: blink-red 1.5s infinite;
}

.blink-orange {
  animation: blink-orange 1.5s infinite;
}

.blink-green {
  animation: blink-green 1.5s infinite;
}

/* Forma y apariencia general de las celdas */
.cell {
  border-radius: 8px; /* Bordes redondeados */
  padding: 5px 10px; /* Espaciado interno */
  color: white; /* Texto blanco */
  font-weight: bold; /* Texto en negrita */
}

.productItem {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}

.productItem-containerAvatar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.productItem-avatar {
  width: 50px;
  height: 50px;
  margin: 5px;
}

@media (max-width: 320px) {
  .productItem {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .productItem-avatar {
    height: 30px;
    width: 30px;
  }

}